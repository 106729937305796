* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html,
body {
    max-width: 100vw;
    overflow-x: hidden;
}

body {
    background-color: #FAFAFA;
    color: #222222 !important;
    font-size: 14px !important;
}

p, span {
    font-size: 14px;
}

small {
    font-size: 12px;
}

textarea {
    height: 120px !important;
    resize: none;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16px' height='16px' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M6.1018 8C5.02785 8 4.45387 9.2649 5.16108 10.0731L10.6829 16.3838C11.3801 17.1806 12.6197 17.1806 13.3169 16.3838L18.8388 10.0731C19.5459 9.2649 18.972 8 17.898 8H6.1018Z' fill='%23212121'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position-x: calc(100% - 10px);
    background-position-y: 49%;
}

.table td {
    border-top: 0.5px dashed #dee2e6;
    padding: .5rem 0px;
    font-size: 14px;
    vertical-align: middle;
}

.table thead tr:first-child td {
    border-top: none;
}

.table-data thead td {
    padding: 0 !important;
}

.table-data td, .table-data th {
    border-top: none;
    border-bottom: none !important;
    padding: 0;
}

.table-data .row-head {
    padding: 10px 20px;
    font-weight: 700;
}

.table-data .row-data {
    border-radius: 15px;
    margin-bottom: 5px;
    padding: 10px 20px;
    border: 0.5px solid #DDDDDD;
    background-color: white;
}

.cursor-pointer {
    cursor: pointer;
}

.btn {
    border-radius: 15px;
    min-height: 46px;
}

.btn.disabled, .btn:disabled {
    cursor: not-allowed;
}

.btn-sm {
    min-height: 35px;
    padding: 5px 10px;
}

.btn-primary {
    background-color: #214162;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
    background-color: #214162;
}

.btn-primary-app {
    background-color: #9ACE51;
    border-color: #90d332;
    color: white;
    padding: 10px 20px 10px 20px;
}

.btn-primary-app:hover,
.btn-primary-app:focus,
.btn-primary-app:active {
    background-color: #90d332;
    border-color: #90d332;
}

.btn-outline {
    background-color: white;
    color: #90d332;
    border: 1px solid #90d332;
}

.btn-outline:hover,
.btn-outline:focus,
.btn-outline:active {
    background-color: #90d332;
    color: white;
    border: 1px solid #90d332;
}

.btn-outline:disabled {
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    color: #495057;
}

.btn-incompleted {
    background-color: #eee;
    border: 1px solid #ddd;
    font-size: 12px;
}

.btn-completed {
    color: #5F8458;
    background-color: #E5F5E2;
    border: 1px solid #C7DDC3;
    font-size: 12px;
}

.btn-approved {
    color: white;
    background-color: #7CC56E;
    border: 1px solid #73BB66;
    font-size: 12px;
}

.btn-revised {
    color: #518CB6;
    background-color: #E3F3FF;
    border: 1px solid #9DC9EA;
    font-size: 12px;
}

.btn-notes-added {
    color: #9E8F42;
    background-color: #EDE09F;
    border: 1px solid #E2D697;
    font-size: 12px;
}

.btn-transparent {
    background-color: transparent;
    border: none;
    box-shadow: none;
}

.btn-radius-20 {
    border-radius: 20px;
}

.btn-row-table {
    border-radius: 10px;
    padding: 10px 15px;
    min-width: 80px;
    font-size: inherit;
    font-weight: 700;
    min-width: 120px;
}

.btn-default {
    background-color: white;
    border: 1px solid #CCCCCC;
}

.btn-detail {
    color: #9ACE51;
    background-color: white;
    border-radius: 10px;
    border: 1px solid #CCCCCC;
}

.custom-control {
    padding-left: 2rem;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    background-color: #73a132;
    border-color: #73a132;
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
    color: #fff;
    background-color: rgb(148 148 148 / 50%);
    border-color: rgb(148 148 148 / 100%);
}

.form-check input[type=radio] {
    margin-right: 12px;
    transform: scale(1.5);
    accent-color: #73a132;
}

.custom-control-label::before {
    position: absolute;
    top: 0.05rem;
    left: -2rem;
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: 1px solid #adb5bd;
}

.custom-control-label::after {
    position: absolute;
    top: 0.05rem;
    left: -2rem;
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    content: "";
    background: 50% / 50% 50% no-repeat;
}

.font-14 {
    font-size: 14px !important;
}

.font-16 {
    font-size: 16px !important;
}

.font-18 {
    font-size: 18px !important;
}

.font-20 {
    font-size: 20px !important;
}

.font-24 {
    font-size: 24px !important;
}

.font-32 {
    font-size: 32px !important;
}

.font-70 {
    font-size: 70px;
}

.fw-300 {
    font-weight: 300;
}

.fw-600 {
    font-weight: 600;
}

.fw-900 {
    font-weight: 900;
}

.form-control {
    padding: 10px 20px;
    border-radius: 15px;
    min-height: 46px;
}

.input-larger {
    height: 57px;
}

.nav-custom {
    border: 0.5 solid #DDDDDD;
    background-color: #F2F2F2;
    border-radius: 10px;
    padding: 5px;
}

.nav-custom .nav-item .nav-link {
    color: #9d9d9d;
    font-weight: bold;
}

.nav-custom .nav-item .nav-link.active {
    background-color: white;
    color: inherit;
    border-radius: 5px;
}

.nav-custom .nav-item-dashboard {
    min-width: 250px;
}

.nav-custom .nav-item-dashboard .nav-link.active i {
    color: #90d332;
}

.nav-header a {
    color: inherit;
}

.nav-header a:hover {
    text-decoration: none;
}

.card {
    background-color: white;
    border-radius: 15px;
    border: 1px solid #eee;
}

.react-datepicker-wrapper {
    width: 100%;
}

@media (max-width: 768px) {
    body {
        font-size: 13px !important;
    }

    .font-14 {
        font-size: 13px !important;
    }

    .font-16 {
        font-size: 15px !important;
    }

    .font-18 {
        font-size: 16px !important;
    }

    .font-20 {
        font-size: 16px !important;
    }

    .font-24 {
        font-size: 20px !important;
    }

    .font-32 {
        font-size: 24px !important;
    }

    p, span {
        font-size: 13px;
    }

    .input-larger {
        height: 46px;
    }

    .nav-custom .nav-item-dashboard {
        min-width: 0px;
    }

    .nav-custom .nav-item-dashboard .nav-link {
        padding: .5rem;
    }
}

@media (max-width: 1024px) {
    .btn-row-table {
        min-width: 100px;
    }
}