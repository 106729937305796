.row-control label {
  font-size: inherit;
  margin-bottom: 8px;
}

.container-form {
  width: 700px;
  padding: 30px;
  max-height: 100%;
  overflow-y: auto;
}

.container-form-confirmation {
  width: 850px;
  padding: 20px;
  margin: auto;
}

.container-form-docusign {
  width: 1200px;
  padding: 20px;
  margin: auto;
}

.container-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  padding: 10px 30px;
  border-bottom: 0.5px solid #DDDDDD;
  height: 90px;
  z-index: 1000;
}

.container-footer {
  border-top: 0.5px solid #DDDDDD;
  background-color: white;
  height: 80px;
  width: 100%;
}

.container-footer-home {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: transparent;
  color: white;
  padding: 10px 30px;
  border-top: none;
  z-index: 1000;
}

.container-footer-main {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: white;
  color: inherit;
  padding: 10px 30px;
  z-index: 1000;
}

.container-footer span {
  font-size: 12px;
  font-weight: 400;
}

.text-primary-app {
  color: #9ACE51 !important;
  text-decoration: none;
}

.bg-home {
  background: url('../images/background/home_background.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.container-home {
  height: 100vh;
  padding-bottom: 80px;
  overflow: auto;
}

.container-main {
  height: 100vh;
  padding-top: 90px;
  padding-bottom: 80px;
  overflow: auto;
}

.container-main.container-main-has-submit {
  padding-bottom: 180px;
}

a.text-primary-app:hover,
a.text-primary-app:focus,
a.text-primary-app:active {
  color: #9ACE51 !important;
  text-decoration: none;
}

.text-title-app {
  font-size: 40px;
  font-weight: 800;
  margin-top: 30px;
}

.container-home .text-title-app {
  color: #FFDE5E;
}

.icon-16 {
  height: 16px;
  cursor: pointer;
}

.icon-24 {
  height: 24px;
}

.icon-32 {
  height: 32px;
}

.icon-blue {
  color: #025CB9;
  size: 14px;
  cursor: pointer;
}

.icon-grey {
  color: #222222;
  size: 14px;
  cursor: pointer;
}

.icon-avatar {
  height: 64px;
  border: 1px solid #ddd;
  border-radius: 100%;
  padding: 8px;
}

.text-label {
  color: #222222;
}

.container-card-item {
  border: 0.5px solid #DDDDDD;
  border-radius: 15px;
  padding: 15px;
  background-color: white;
  position: relative;
}

.container-full-screen {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  right: 0;
  bottom: 0;
  right: 0;
  z-index: 1050;
}

.container-card-item .container-image {
  width: 100%;
  height: 360px;
  border-radius: 10px;
}

.container-full-screen.container-card-item .container-image {
  max-height: calc(100vh - 140px);
}

.container-card-item .container-title {
  border-bottom: 1px dashed #DDDDDD;
}

.container-card-item .container-slide {
  position: absolute;
  z-index: 100;
  bottom: 15px;
  right: 15px;
}

.container-tool {
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.8);
}

.container-tool .container-icon {
  padding: 10px 15px;
  cursor: pointer;
}

.container-tool-circle {
  border-radius: 100%;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  width: 44px;
  height: 44px;
  text-align: center;
  cursor: pointer;
}

.container-title-day-small {
  font-size: 12px;
  border-radius: 20px;
  background-color: #5EBDDB;
  font-weight: 900;
  color: white;
  padding: 5px 10px;
  width: 120px;
  text-align: center;
}

.container-title-number {
  background-color: #214162;
  border-radius: 100%;
  font-weight: bold;
  position: relative;
  height: 28px;
  width: 28px;
}

.container-title-number-small {
  height: 24px;
  width: 24px;
}

.container-title-number-smaller {
  height: 20px;
  width: 20px;
}

.container-title-number span {
  position: absolute;
  width: 28px;
  top: 3px;
  left: 0px;
  text-align: center;
}

.container-title-number-small span {
  top: 3px;
  width: 24px !important;
  font-size: 12px;
}

.container-title-number-smaller span {
  top: 3px;
  width: 20px !important;
  font-size: 9px;
}

.container-section .container-content {
  padding-left: 36px;
}

.container-files .item {
  background-color: #F3F3F3;
  border: 0.5px solid #DDDDDD;
  border-radius: 15px;
  margin-bottom: 10px;
}

.container-files .item:last-child {
  margin-bottom: 0;
}

.container-upload-files {
  border-radius: 15px;
  background-color: #F4F7F9;
  border: 1px solid #9DC2D8;
  height: 200px;
  width: auto;
  cursor: pointer;
}

.container-btn-bottom {
  position: fixed;
  bottom: 80px;
  left: 0;
  padding: 20px 30px;
  border-top: none;
  z-index: 1001;
  border-top: 0.5px solid #DDDDDD;
  background-color: white;
  width: 100%;
}

.container-btn-bottom button {
  min-width: 300px;
}

.container-offer-left {
  width: 750px;
}

.container-offer-right {
  width: 450px;
  border-radius: 15px;
  background-color: white;
  border: 0.5px solid #DDDDDD;
}

.container-list-info .item {
  border-bottom: 1px dashed #DDDDDD;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.container-list-info .item:last-child {
  border-bottom: none;
}

.container-item-dashboard {
  border: 0.5px solid #DDDDDD;
  border-radius: 15px;
  background-color: white;
}

.row-table-activity {
  background-color: white;
  border: 0.5px solid #DDDDDD;
  border-radius: 15px;
  min-width: 120px;
}

.container-status-open {
  background-color: #D0F4C7;
  border: 1px solid #94D983;
  color: #44C623;
  border-radius: 20px;
  padding: 4px 20px;
  font-weight: 700;
  min-width: 120px;
  text-align: center;
}

.container-status-submitted {
  background-color: #c7e4f4;
  border: 1px solid #83b7d9;
  color: #239dc6;
  border-radius: 20px;
  padding: 4px 20px;
  font-weight: 700;
  min-width: 120px;
  text-align: center;
}


.container-status-rejected {
  background-color: #F4E9E9;
  border: 1px solid #DDB1B1;
  color: #D23D3D;
  border-radius: 20px;
  padding: 4px 20px;
  font-weight: 700;
  min-width: 120px;
  text-align: center;
}

.container-status-closed {
  background-color: #EFEFEF;
  border: 1px solid #D0D0D0;
  color: #909090;
  border-radius: 20px;
  padding: 4px 20px;
  font-weight: 700;
  min-width: 120px;
  text-align: center;
}

.container-status-paid {
  background-color: #beffe5;
  border: 1px solid #71ccb4;
  color: #909090;
  border-radius: 20px;
  padding: 4px 20px;
  font-weight: 700;
  min-width: 120px;
  text-align: center;
}

.container-message .text-title {
  font-weight: 900;
  font-size: 16px;
}

.container-message .item-left {
  border-radius: 20px;
  border-bottom-left-radius: 0px;
  background-color: #FAF5E3;
  padding: 15px 20px;
}

.container-message .item-right {
  border-radius: 20px;
  border-bottom-right-radius: 0px;
  background-color: #E6F6F8;
  padding: 15px 20px;
}

.input-search-filter {
  width: 250px;
}

.input-group-transparent .form-control {
  border-right: none;
}

.input-group-checkbox .input-group-text {
  border: none;
  background-color: transparent;
  padding: 0;
}

.input-group-checkbox .form-control {
  border: none;
  background-color: transparent;
  padding: 0px;
  min-height: 32px;
  box-shadow: none;
}

.input-group-transparent .input-group-text {
  background-color: white;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.container-disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.container-warning {
  background-color: #FBF7E5;
  border: 1px solid #EDE09F;
  padding: 20px;
  border-radius: 15px;
}

.container-icon-dashboard {
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

.pagination .page-item .page-link {
  color: inherit;
  background-color: transparent;
  border: none;
  width: 36px;
  text-align: center;
  padding: .5rem;
}

.pagination .page-item.disabled .page-link {
  opacity: 0.3;
  cursor: not-allowed;
}

.pagination .page-item .page-control {
  width: 48px;
}

.pagination .page-item.active .page-link {
  font-weight: 900;
}

.was-validated .form-control.is-invalid-custom {
  border-color: #c8102e !important;
  box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important;
}

.was-validated .form-control.is-invalid-custom .invalid-feedback {
  display: block;
}

.container-loading {
  z-index: 9999;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
}

.container-editor .ck-content {
  height: calc(100vh - 260px);
}

.text-state-name {
  height: 24px;
  text-align: left;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.container-iframe-docusign {
  height: calc(100vh - 280px);
  overflow: auto;
  border: 1px solid #ddd;
  background-color: #eee;
}

/* bg:e4e4e4 , border: #dbdbdb -> light */
/* bg:1e1f26 , border: #2d2e36 -> dark */

.sugesstion-auto {
  display: block;
  border-radius: 7px;
  position: relative;
  transition: background 0.3s ease;
}

.form-control-auto {
  background-color: #1e1f26 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.form-control-auto input {
  border: 0;
  border-radius: 5px;
  width: 100%;
  padding: 15px 20px;
  outline: none;
}

.form-control-auto label {
  font-size: 10px;
  text-transform: uppercase;
  color: #949494;
  padding: 10px 0px 10px 20px;
}

.suggestions {
  position: fixed;
  top: auto;
  left: auto;
  z-index: 9999;
  cursor: pointer;
  width: 100%;
  max-height: 240px;
  overflow: auto;
  background-color: white;
  border-radius: .25rem;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
}

.sugesstion-loading {
  position: absolute;
  z-index: 100;
  right: 14px;
  top: 20px;
}

.suggestions > div {
  border: 1px solid #eee;
  transition: all 0.1s ease;
}

.suggestions > div:hover {
  background-color: #f6f6f6 !important;
}

.suggestions > div {
  background-color: white;
}

.pac-container {
  z-index: 9999;
}

.popover {
  min-width: 480px;
}

.popover-body {
  max-height: 320px;
  overflow: auto;
}

@media (max-width: 768px) {
  html,body{
    -webkit-overflow-scrolling : touch !important;
    overflow: auto !important;
    height: 100% !important;
  }
  
  .popover {
    min-width: 360px;
  }

  .container-footer {
    height: 120px;
  }

  .container-footer-home {
    position: relative;
    margin-top: -120px;
    padding: 16px;
  }

  .container-footer-relative {
    position: relative;
    margin-bottom: 90px;
    padding: 16px;
    background-color: #FAFAFA;
    border-top: none;
    height: 0;
    padding: 0;
  }

  .container-iframe-docusign {
    height: calc(100vh - 280px);
  }

  .container-btn-bottom {
    bottom: 0px;
  }

  .container-btn-bottom button {
    min-width: calc(100% / 2 - 10px);
  }

  .container-header,
  .container-footer {
    padding: 15px;
  }

  .container-header img.image-logo {
    height: 24px;
    width: auto;
  }

  .container-footer img.image-logo {
    height: 20px;
    width: auto;
  }

  .text-title-app {
    font-size: 24px;
  }

  .container-home {
    height: auto;
    min-height: 100vh;
    overflow: auto;
    padding-top: 40px;
    padding-bottom: 135px;
  }

  .container-main {
    height: auto;
    min-height: auto;
    overflow: auto;
    padding-top: 105px;
    padding-bottom: 135px;
  }

  .container-main-no-padding-bottom {
    padding-bottom: 0px;
  }

  .container-main.container-main-has-submit {
    padding-bottom: 60px;
  }

  .container-section .container-content-no-padding {
    padding-left: 0px !important;
  }

  .container-offer-left,
  .container-offer-right {
    width: 100%;
  }

  .container-item-dashboard {
    height: 165px;
  }

  .container-card-item .container-slide {
    position: absolute;
    justify-content: center !important;
    left: 0;
    width: 100%;
  }

  .text-item-card {
    text-align: left !important;
  }

  #nav-admin-dashboard {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .container-offer-right .container-list-info {
    max-height: 400px;
    overflow: auto;
  }

  .container-card-item .container-image {
    height: 280px;
  }

  .container-editor .ck-content {
    height: calc(100vh - 320px);
  }

  .container-form-confirmation,
  .container-form-docusign {
    width: 100%;
    padding: 20px;
    margin: auto;
  }

  .sugesstion-loading {
    top: 16px;
    right: 12px
  }
}